.whatsapp-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px; /* Reduced padding for a smaller overall component */
}

.whatsapp-link {
    display: flex;
    align-items: center;
    background-color: #25D366; /* WhatsApp green */
    color: white;
    text-decoration: none;
    padding: 8px 16px; /* Smaller padding for a compact button */
    border-radius: 30px; /* Slightly smaller border-radius for a compact look */
    transition: background-color 0.3s ease;
    font-weight: bold; /* Bold text */
    font-size: 1rem; /* Slightly smaller text size */
}

.whatsapp-text {
    margin-right: 8px; /* Space between the text and the image */
    font-size: 1rem; /* Small but readable text size */
}

.whatsapp-link img {
    width: 20px; /* Smaller image size for better UX */
    height: 20px;
}

.whatsapp-link:hover {
    background-color: #1ebe5b;
}